import { usePathname } from 'next/navigation'
import { useMemo } from 'react'

import { removeQueryAndHashFromPathname } from '../utils/pages'
import { getTabPageIdForPath, TabPage } from '../utils/tabs'
import useSelectedTab from './useSelectedTab'

const useSelectedTabPage = (): TabPage | undefined => {
  const pathname = removeQueryAndHashFromPathname(usePathname())

  const selectedTab = useSelectedTab()

  return useMemo(() => {
    if (!selectedTab) {
      return
    }
    const selectedPageId = getTabPageIdForPath(pathname)
    if (!selectedPageId) {
      return
    }
    return selectedTab.pages.find((page) => page.id === selectedPageId)
  }, [pathname, selectedTab])
}

export default useSelectedTabPage
